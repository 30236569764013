/* */



.columns {
  float: center; 
  width: 50%;
  padding: 8px;
  /* margin: auto; */
}
.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
}


.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
  font-size: 1.0rem;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}



@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}