.navbar {
  background-color: #6c55ff !important;
  padding: 30px 0px ;
  transition: all 0.5s ease;
  
}


.navbar .navbar-brand {
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
  margin: auto;
  
}


.navbar > .container {
  padding: 0 10px;
  /* width: 500px !important; */
}

.navbar.navbar-shrink {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--main-color);
  padding: 10px 10px;
}

.navbar .nav-item {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 25px;
  padding: 10px 10px;
}
.navbar .nav-item .nav-link {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 0;
  position: relative;
}
.navbar .nav-item .nav-link .link {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 0;
  position: relative;
}
.navbar .nav-item .nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  background-color: #ffffff;
  transition: all 0.5s ease;
  transform: scale(0);
}
.navbar .nav-item .nav-link.active::before,
.navbar .nav-item .nav-link:hover::before {
  transform: scale(1);
}
