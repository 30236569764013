/* OwlCarouselComponent.css */

.features{
  margin: 10px;
  
}
.carousel-item {
    display: flex;
    margin: auto auto;
    justify-content: center;
  }

  .owl-dots{
    display: flex;
    width: 100px;
    margin: auto auto;
    
  }
  
  .carousel-box {
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 1rem;
    color: black;
    width: 100%;
    height: 100%;
  }
  
  .carousel-box img {
    max-width: 50px;
    margin-bottom: 10px;
  }
  
  /* Optional: Add responsive CSS for different screen sizes */
  @media (max-width: 576px) {
    .carousel-item {
      margin: 0 auto;
      max-width: 80%;
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .carousel-item {
      margin: 0 auto;
      max-width: 50%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .carousel-item {
      margin: 0 auto;
      max-width: 90%;
    }
  }
  
  @media (min-width: 993px) {
    .carousel-item {
      margin: 0 auto;
      max-width: 95%;
    }
  }
  





  