
.contact-us {
    padding: 50px ;
    min-height: 100vh;
  }
  
  .section-header {
    margin-bottom: 30px;
  }
  
  .contact-form {
    margin-bottom: 40px;
  }
  
  .contact-info h2 {
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .contact-info p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .contact-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .footer {
    background-color: #f8f9fa;
    text-align: center;
    padding: 20px 0;
  }
.p{
    font-size: 1.5rem; 
 }
 
 @media (max-width: 576px) {
     .p{
         font-size: 1rem; 
      }
  }
   