form {
  padding: 20px !important;
  margin: 10px;
  border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  border: 1px solid #ccc;

  box-shadow: 10px 10px 13px -6px rgba(0, 0, 0, 0.56);
  -webkit-box-shadow: 10px 10px 13px -6px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: 10px 10px 13px -6px rgba(0, 0, 0, 0.56);
}

button {
  width: 100%;
}
